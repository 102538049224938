<template>
  <div id="demo">
    <calendar/>
  </div>
</template>

<script>

// 或者在.vue文件中单独引入注册
import {calendar,inlineCalendar} from 'vue-mobile-calendar';
export default {
  components: {
    calendar
  }
}
</script>
